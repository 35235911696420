<template>
    <div>
        <div class="title">{{$t('titleI18n.BankRegion')}}</div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
            <el-form-item :label="$t('formTitleI18n.BankCountryRegionCN')" prop="cnName">
                <el-input v-model="ruleForm.cnName"></el-input>
            </el-form-item>
            <el-form-item :label="$t('formTitleI18n.BankCountryRegionEN')" prop="enName">
                <el-input v-model="ruleForm.enName"></el-input>
            </el-form-item>
            <el-form-item :label="$t('formTitleI18n.BankCountryCode')" prop="code">
                <el-input v-model="ruleForm.code"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button @click="toBack">{{ $t("btnI18n.Cancel") }}</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')">{{ $t("btnI18n.Save") }}</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: "bank-area-ad",
    data() {
        return {
            ruleForm: {
                cnName: '',
                enName: '',
                code: '',
            },
            rules: {
                cnName: [
                    { required: true, message: this.$t("validateFormTips.requireCR"), trigger: 'blur' },
                ],
                enName: [
                    { required: true, message: this.$t("validateFormTips.requireCR"), trigger: 'blur' },
                ],
                code: [
                    { required: true, message: this.$t("validateFormTips.requireCRCode"), trigger: 'blur' },
                ],
            }
        };
    },
    mounted() {
        this.initData();
        this.resetForm("ruleForm");
    },
    methods: {
        initData() {
            if (this.$route.query.id) {
                this.getBankInfo();
            }
        },
        getBankInfo() {
            const params = {
                id: this.$route.query.id,
            };
            let _this = this;
            this.$axios.get("/manage-bank/area-detail", { params: params }).then(res => {
                if (!res.code) {
                    _this.ruleForm = res.data;
                }
            });
        },
        submitForm(formName) {
            let _this = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    delete _this.ruleForm.createdAt;
                    delete _this.ruleForm.deletedAt;
                    delete _this.ruleForm.updatedAt;
                    delete _this.ruleForm.enable;
                    _this.ruleForm.code = _this.ruleForm.code.toString();
                    _this.$axios.post("/manage-bank/area-create", _this.ruleForm).then(res => {
                        if (!res.code) {
                            _this.toBack();
                        } else {
                            _this.$message.error(res.message);
                        }
                    });
                } else {
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        toBack() {
            this.resetForm("ruleForm");
            this.$router.push({path: "/bank-area"});
        },
    }
};
</script>

<style scoped>

</style>
